import * as React from "react";
import { Link, graphql } from "gatsby";
import {
  LayoutSl,
  SEO2,
  CJumbotron,
  CBreadCrumb,
  LWrap2,
  CSectTitle,
  CColorCard,
  CLabelCard,
  LRecruitUniqueSect,
  CVideoMedia,
  CVideo,
  AssetImage,
  CRecruitJumbtron,
  CTextImgCard,
  RecruitNews,
  Vertical,
  CBtnList,
  CHeroMedia,
  CHotelList,
  ContainerCrumbs
} from "../../../components/_index";
import { useMobile } from '../../../utils/use-series';
import { size } from "lodash";
import { title } from "process";
import '../../../assets/_sass/page/recruit/data.scss';

// markup
const SubPage = ({ data }: { data: any }) => {
  const isSp = useMobile();
  const frontmatter = data?.markdownRemark?.frontmatter;
  const crumbsData = [
    {
      title: "Top",
      src: "/recruit/",
    },
    {
      title: "数字で見る三菱地所ホテルズ＆リゾーツ",
      src: "/recruit/data/",
    },
  ];

  return (
    <LayoutSl isKv={false}>
      <SEO2 title={frontmatter?.title} description={frontmatter?.description} isTop={false} />
      <section className="l_sect05 mmb-10 u_bgWhite recruit-font">
        <LWrap2>
        <ContainerCrumbs data={crumbsData} />
        <div className="r-data-head-container">
            <div className="r-data-head-left">
              <div className="r-data-head-jp data-head-pc">三菱地所ホテルズ＆リゾーツ</div>
              <div className="r-data-head-jp data-head-pc">数字で見る</div>
              <div className="r-data-head-jp data-head-media">数字で見る</div>
              <div className="r-data-head-jp data-head-media">三菱地所ホテルズ＆</div>
              <div className="r-data-head-jp data-head-media">リゾーツ</div>
              <div className="r-data-head-en">Data</div>
            </div>
            <div className="r-data-head-right">
            <div className="r-data-head-article">
              <span className="r-data-head-article-content"> 三菱地所ホテルズ＆リゾーツの情報を数字で紹介いたします。</span>
              <p><br /></p>
              <p><br /></p>
              <span className="r-data-head-article-title"> 三菱地所ホテルズ＆<br className="u_sp"/>リゾーツについて</span>
              <p><br /></p>
            </div>
            <div className="r-data-head-img-container">
              <AssetImage src="/assets/images/recruit/data/data.png"
                alt=""
                loading="lazy" />
              
              <AssetImage src="/assets/images/recruit/data/data02.png"
                alt=""
                loading="lazy" />
              
              <AssetImage src="/assets/images/recruit/data/data03.png"
                alt=""
                loading="lazy" />
              
              <AssetImage src="/assets/images/recruit/data/data04.png"
                alt=""
                loading="lazy" />
       
            </div>
            <div className="r-data-head-article-a">
              <span className="r-data-head-article-title-a"> 社員について</span>
            
            </div>
            <div className="r-data-head-img-container">
              <AssetImage src="/assets/images/recruit/data/data05.png"
                alt=""
                loading="lazy" />
             
              <AssetImage src="/assets/images/recruit/data/data06.png"
                alt=""
                loading="lazy" />
             
              <AssetImage src="/assets/images/recruit/data/data07.png"
                alt=""
                loading="lazy" />
              
              <AssetImage src="/assets/images/recruit/data/data08.png"
                alt=""
                loading="lazy" />
              
              <AssetImage src="/assets/images/recruit/data/data09.png"
                alt=""
                loading="lazy" />
          
              <AssetImage src="/assets/images/recruit/data/data10.png"
                alt=""
                loading="lazy" />
            
              <AssetImage src="/assets/images/recruit/data/data11.png"
                alt=""
                loading="lazy" />
             
              <AssetImage src="/assets/images/recruit/data/data12.png"
                alt=""
                loading="lazy" />
         
              <AssetImage src="/assets/images/recruit/data/data13.png"
                alt=""
                loading="lazy" />
             
              <AssetImage src="/assets/images/recruit/data/data14.png"
                alt=""
                loading="lazy" />
          
              <AssetImage src="/assets/images/recruit/data/data15.png"
                alt=""
                loading="lazy" />
             
              <AssetImage src="/assets/images/recruit/data/data16.png"
                alt=""
                loading="lazy" />
        
            </div>
            <div className="r-data-head-article-a">
              <span className="r-data-head-article-title-a"> 採用について</span>
            
            </div>
            <div className="r-data-head-img-container">
              <AssetImage src="/assets/images/recruit/data/data17.png"
                alt=""
                loading="lazy" />
           
              <AssetImage src="/assets/images/recruit/data/data18.png"
                alt=""
                loading="lazy" />
            
              <AssetImage src="/assets/images/recruit/data/data19.png"
                alt=""
                loading="lazy" />
            
            </div>
            <div className="r-data-head-article-a">
              <span className="r-data-head-article-title-a"> 人財育成について</span>
              
            </div>
            <div className="r-data-head-img-container">
              <AssetImage src="/assets/images/recruit/data/data20.png"
                alt=""
                loading="lazy" />
              
              <AssetImage src="/assets/images/recruit/data/data21.png"
                alt=""
                loading="lazy" />
             
            </div>
          </div>
        </div>
        
        </LWrap2>
      </section>
    </LayoutSl>

  );
};

export default SubPage;

export const query = graphql`
  query ($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
        description
      }
    }
  }
`;
